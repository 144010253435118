import React from "react";
import AboutIndex from "./AboutIndex";
import {SlBadge} from "react-icons/sl"
import {PiChatsDuotone} from "react-icons/pi"
import {GiTreeGrowth} from "react-icons/gi"
import {MdManageAccounts} from "react-icons/md"
import {whower }from '../../assets/index'


const About = () => {

    const iconStyle = {
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
      };
      const alignMent = {
        textAlign:'center',
       margin:'auto',
      };
  return (
    <div >
      <AboutIndex />
   
                    
      <section className="bg-gray-50 dark:bg-gray-800">
        <div className=" px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 ">
            <figure className="max-w-screen-md mx-auto">
              <h1 className="text-3xl font-bold">Our Mission </h1>
              <blockquote>
                <p className="text-sm font-medium text-gray-900 md:text-xl dark:text-white text-start p-5">
                  Our mission is to provide value to our customers. Value being
                  a factor of “Effective Software”, “Latest Technology”,
                  “Intuitive Designer”, “Predictable Delivery”, &
                  “Distinctiveness”.
                </p>
              </blockquote>
            </figure>
        
            <figure className="max-w-screen-md mx-auto ">
              <h1 className="text-3xl font-bold">Our Vision</h1>
              <blockquote>
                <p className="text-sm font-medium text-gray-900 md:text-xl dark:text-white text-start p-5">
                  Our vision to be a leading product & services provide by
                  enabling people to maximize their efficiency & make them
                  success through our technical talent, genies ideas &
                  profession.
                </p>
              </blockquote>
            
            </figure>
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-gray-900">
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
        <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                <div  className="flex item-center justify-center flex-col">
                     <h3 style={alignMent}><SlBadge size={60} color="goldenrod" style={iconStyle}/></h3> 
                    <h3 className="mb-2 text-2xl font-bold dark:text-white"style={alignMent}>Best Security</h3>
                    <p className="font-light text-gray-500 dark:text-gray-400"style={alignMent}>We have seen great successes with everyone companies.</p>
                </div>
                <div className="flex item-center justify-center flex-col">
                    <h3 style={alignMent}><PiChatsDuotone size={60} color="#d84ce3" style={iconStyle}/></h3> 
                    <h3 style={alignMent} className="mb-2 text-2xl font-bold dark:text-white">Risk Manage</h3>
                    <p  style={alignMent} className="font-light text-gray-500 dark:text-gray-400">Every business and industry requires an approach in our Heand.</p>
                </div>
                <div className="flex item-center justify-center flex-col">
                <h3 style={alignMent}><GiTreeGrowth size={60} color="#0028a3" style={iconStyle}/></h3> 
                    <h3 style={alignMent} className="mb-2 text-2xl font-bold dark:text-white">Growth Revenue</h3>
                    <p style={alignMent} className="font-light text-gray-500 dark:text-gray-400">You make sure you know how campaign is performing Business</p>
                </div>
                <div className="flex item-center justify-center flex-col">
                <h3 style={alignMent}><MdManageAccounts size={60} color="#bb0b15" style={iconStyle}/></h3> 
                    <h3 style={alignMent} className="mb-2 text-2xl font-bold dark:text-white">Greate Support</h3>
                    <p style={alignMent} className="font-light text-gray-500 dark:text-gray-400">To generate highly focused leads ready to purchases our service</p>
                </div>
            </div>
            <div className="col-span-2 mb-8">
                <p className="text-lg font-medium text-purple-600 dark:text-purple-500">WHY US CHOOSE NOW</p>
                <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">We Provide the Best Facilites For Business</h2>
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">From keyword research to technical auditing to site migration, our team of technical SEOs are true experts in their field.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        We've been supporting WordPress since the beginning.
                        
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Perfect for large sites or agencies managing multiple clients.
                         
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Our easy-to-use control panel and API let you spend.
                         
                      </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
           
           
          
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                <img className="hidden w-full mb-4 rounded-xl lg:mb-0 lg:flex" src={whower}alt="whower" loading="lazy"/>
                <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">WHO WE ARE</h2>
                    <h2>We primarily focus on developing, marketing, and selling software products. </h2>
                    <p className="mb-8 font-light dark:text-white lg:text-xl">These products could range from mobile apps, web applications, enterprise software, cloud-based solutions, and more</p>
                 
                    <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                        <li className="flex space-x-3">
                       
                            <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Innovation and R&D: We often emphasize research and development to create cutting-edge products..</span>
                        </li>
                        <li className="flex space-x-3">
                       
                            <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Product Lifecycles: We follow a structured product development lifecycle, from ideation and prototyping to testing, deployment, and ongoing support.</span>
                        </li>
                        <li className="flex space-x-3">
                      
                            <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Customer-Centric Approach: We are Successful product-based organization. prioritize understanding our customers' needs and pain points. </span>
                        </li>
                        <li className="flex space-x-3">
                        
                            <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Cross-Functional Teams: We  have cross-functional teams comprising product managers, developers, designers, testers, and other specialists.</span>
                        </li>
                        <li className="flex space-x-3">
                        
                            <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Customer Support and Service: Providing excellent customer support is crucial for our product-based organization to retain customers and build a loyal user base .</span>
                        </li>
                    </ul>
                    <p className="font-light dark:text-white lg:text-xl dark:text-white">We focus on optimizing customer's solutions to handle large-scale usage.</p>
                </div>
            </div>
        </div>
    </section>
    
    </div>
  );
};

export default About;
