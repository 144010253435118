import React, { useEffect, useRef } from 'react';


const ContinuousMarquee = ({ children, speed }) => {
  const marqueeRef = useRef();

  useEffect(() => {
    const marqueeElement = marqueeRef.current;

    const animation = marqueeElement.animate(
      [
        { transform: 'translateX(100%)' },
        { transform: 'translateX(-100%)' },
      ],
      {
        duration: speed,
        iterations: Infinity,
      }
    );

    return () => {
      animation.cancel();
    };
  }, [speed]);

  return (
    <div className="marquee-container">
      <div className="marquee-content" ref={marqueeRef}>
        {children}
      </div>
    </div>
  );
};

export default ContinuousMarquee;
