import React from 'react';
import {sdcs,techstack,saudit,scaleproduct,takeover,Leanstartup,devop,agile,digital  } from "../../../assets/index"




const Softwaredevelopmentconsulting = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Software Development Consulting</p>
      <div className='flex items-center justify-center rounded'>
        <img src={sdcs} alt='Software Development Consulting' className='rounded-2xl' style={{width:'60%',height:'auto'}} loading="lazy"/>
      </div>
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center mt-2">We help plan and implement software improvements as well as staff software development projects with skilled resources.</p>
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center mt-8">What we deliver</p>
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
     
        <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Tech stack recommendation</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Python or Node.js, React or Angular? Choosing the most efficient technology will support your solution on the long run.</p>
                
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={techstack} alt='Tech Stack' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
            <div className="col-span-2  text-center">
                
               
                <img src={saudit} alt='Scalable Product' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
             
            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Software Audit</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">There are number of reasons of weak performance of the application; starting from old and not supported technologies through not effective code. Detecting the flaws is the key for succesful improvements.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                </div>
            </div>

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Product scale up</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">It's difficult to predict growth rates of application. However, it's important that you can expand its infrastructure, improve its look and feel or increase your team, dealing with one software development partner.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={scaleproduct} alt='Product scale up' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
            <div className="col-span-2   text-center">
                
               
               <img src={takeover} alt='Project takeover' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Project takeover</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Change of requirements or dissatisfaction in current cooperation often leads to necessity to find new team to handle project. Trial period with new developers will be a good indicator for the future cooperation.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
           
             <div className="col-span-2 mb-8">
                 <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Lean startup</p>
                
                 <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">From research through ideation, design, prototyping and tests with users to development - following those steps brings the product closer to a success.</p>
                 <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                 </div>
             </div>
             <div className="col-span-2   text-center">
                
               
                <img src={Leanstartup} alt='Lean startup' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>

             <div className="col-span-2   text-center">
                
               
                <img src={devop} alt='Devops consulting' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
             <div className="col-span-2 mb-8">
                 <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Devops consulting</p>
                
                 <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Servers and infrastructure configuration are a part of development process of any solution. Continuous efficiency to maintain full synergy is required by changing enviroment.</p>
                 <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                 </div>
             </div>

             
          
             <div className="col-span-2 mb-8">
                 <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Digital transformation </p>
                
                 <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Modification of existing business processes and customer experiences to reply on new needs is a part of constant changes present nowadays. New solutions are a reply on market requirements, so being up to date keeps companies circuling.</p>
                 <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                 </div>
             </div>
             <div className="col-span-2   text-center">
                
               
                <img src={digital} alt='Digital transformation' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>

             <div className="col-span-2   text-center">
                
               
                <img src={agile} alt='Digital transformation' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
             <div className="col-span-2 mb-8">
                 <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Agile consultancy</p>
                
                 <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Agile is not only a buzzword, it is the approach that really works. Close collaboration in teams together with flexible way of creating things and responding on actual needs is the key value in modern worklife.</p>
                 <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                 </div>
             </div>
            
           
           
        
        
        
            
       
            
        </div>
        
      
    
        {/* <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
        <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p>
         */}

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default Softwaredevelopmentconsulting;
