import React from 'react';
import {hospo,hospital,hospo2} from '../../../assets/index'




const Hospo = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
 <div className='flex items-center justify-center flex-row'><img src={hospo} alt='hospo' style={{width: '80px',height:'auto'}} loading="lazy"/><span className='dark:text-white text-gray-700' style={{fontFamily: "'Pacifico', cursive", fontSize: '3rem'}} >Hospo</span> </div>
      {/* <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Dextro </p> */}
      <div className='flex items-center justify-center rounded'>
        <img src={hospital} alt='restaurant' className='rounded-2xl' style={{width:'40%',maxHeight:'450px',height:'auto'}} loading="lazy"/>
      </div>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">A Cloud-Based Hospital Information System An all-encompassing solution that allows the transformation of hospital operations and delivery of enhanced patient care.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">Achieving Operational Efficiency & Optimal Revenue The HOSPO is a modern solution that enables medical facilities to overcome the biggest challenges in healthcare delivery today. It was created with a special focus on affordability, scalability and ease-of-use.</p>
      
       
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Key Features</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 1.Ease of Management.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">The solution allows a hospital to manage all departments, patients, and staff with ease. </p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 2. Increased Revenue</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white ">The increased operational efficiency will reduce revenue leakages and stock pilferage, bringing about increased revenue.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 3. Improved Patient Experience</p>

      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">A comprehensive Patient Management Portal ensures that patient wait times are minimised and better care is provided.</p>
      <p className="text-3xl mb-4 font-bold  text-start dark:text-white"> 4.Cost Effective</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">Subscription model removes the need for capital expenditure spending. No upfront total licence fee and minimal infra cost involved.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 5.Enhanced Security.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">An experienced clinic management implementation services team ensures extensive protection from security breaches. This includes protecting computers, networks, programs and data from unauthorised access or damage.</p>
   

      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Why Hospo ?</p>
      <p style={{fontSize:'20px'}} className=" mb-4  text-start mt-8 dark:text-white">With out-of-the-box features and an easy to use interface, the platform is designed for rapid implementation. </p>

        
        <div className="items-center max-w-screen-xl px-4  mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24  lg:px-6">
     
        <div className="col-span-2 mb-8">
             
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Increase patient’s engagement in and ownership of their own health.</p>
                   
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Connect with the doctor through a convenient, safe and secure way.</p>
               
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Send test results to patients quickly and securely.</p>
              
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Better patient – physician relationship.</p>
              
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Greater focus on patient care & improve clinical outcomes.</p>
               
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Patients can view their visit notes in the portal.</p>

                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Hassle free online payment processing.</p>

                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Easily send reminders to patients for medications refill, appointments & pending payments.</p>






              
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={hospo2 } alt='Restorant from home' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
          
         </div>
        {/* <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
        <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p>
         */}
        

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default Hospo;
