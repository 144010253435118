import React from "react";
import {aboutus} from "../../assets/index";


const AboutIndex = () => {
  return (
    <div>
      <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 p-10">
        <img
          className=" w-full mb-4 rounded-lg lg:mb-0 lg:flex sm:flex"
          src={aboutus}
          alt="feature"
          loading="lazy"
        />
        <div className="text-gray-500 sm:text-lg dark:text-gray-400 w-4/3">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white text-center">
            About Us
          </h2>
          <p className="mb-8  lg:text-2xl dark:text-white">
            "If there is one thing that defines us, it is our passion for
            solving business problems utilizing modern technologies".
          </p>

          <p className=" lg:text-xl pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700 dark:text-white">
            BitBlue Technology is a firm of experienced designers, strategists
            and technologists set out to make the future happen. We discover and
            create new business opportunities, concepts and interactive products
            and services that our customers fall in love with — every day..
          </p>
          {/* <Link to="/aboutus">
            <button className="bg-red-900 rounded p-4 text-white">
              Learn More
            </button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default AboutIndex;
