import React from 'react';
import {mlaai,accelerate}  from "../../../assets/index"


const Machine = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 dark:text-white text-center">Machine Learning & AI</p>
      <div className='flex items-center justify-center rounded'>
        <img src={mlaai} alt='Machine Learning and AI' className='rounded-2xl' style={{width:'60%',height:'auto'}} loading="lazy"/>
      </div>
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">As enterprises re-invent themselves amid uncertainties, artificial intelligence (AI) and machine learning (ML) technologies are becoming critical to maneuver their way forward to a promising future. However, with a lack of talent, business scalability, and inability to identify suitable business use cases, these enterprises often lose their chances of a winning streak with AI-ML. Execute your audacious AI-ML strategies with our highly focused, action-driven plan to drive operational excellence and business innovation across your enterprise. At BitBlue Technology, our clients have experienced 30% savings across business functions with our award-winning AI-ML boutique of solutions.</p>
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center mt-8">Features</p>

      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-start">The acceleration in AI-ML adoption leads to the rise of many new and complex challenges. To stay ahead of these challenges and address them through an appropriate action plan, Responsible AI is the cornerstone to our approaches at Bitblue. With Responsible AI, we ensure transparent, accountable, sustainable, and secure technology to our clients. Bitblue Technology helps its clients unlock new opportunities faster, with responsibility being the bottom line of what we do. Ensuring appropriate design, development, and deployment of our technologies is not just our choice but the way forward. We interact and engage with our clients to help them understand and adopt the growing imperative of a safe and responsible AI, which is essential for long-term growth.</p>

      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Accelerate change for a promising future with AI-ML</p>
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
     
        <div className="col-span-2 mb-8">
           
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Managing a client’s SEO consists of many different timelines and action items. Keeping track of client emails, meetings, and central documents is a full-time job.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-dark-600 hover:text-dark-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Build intelligent enterprises, deliver measurable customer experience, and adhere to risk and compliance by implementing successful AI-ML engagements with an IT expert you can trust. Our home-grown capabilities backed by curated partnerships with leading academic universities and vendors give our clients an edge to stay ahead of the digital curve.
                        
                      </div>
                    </div>
                   
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={accelerate} alt='accelerate' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
          

        </div>
        {/* <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
        <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p>
         
         */}

        
    </section>

     
    </div>
  );
};

export default Machine;
