import React from 'react'
import ResponsiveCard from '../ResponsiveCard'
import {dextro,pabulum,hospo,bithome,title_icon} from '../../assets/index.js'


const Product = () => {
  return (
    <div>
      <section className="bg-white dark:bg-gray-900 mb-20 ">
   <div className='items-center flex flex-row justify-center'><img src={title_icon} alt='titleIcon' loading="lazy"/>&nbsp;&nbsp;<h1 className='text-center font-bold text-4xl'>OUR PRODUCTS</h1>&nbsp;&nbsp;<img src={title_icon} alt='titleIcon' loading="lazy"/></div> 
    <div className='flex justify-center flex-col flex-wrap' style={{flexDirection:'row'}}>
    <ResponsiveCard
      imageSrc={dextro}
      title="Dextro"
      description="The Ultimate College/School ERP with Mobile App for modern College/School system Comprehensive, Easy to use web based school management system helps you to move faster, save money, integrate on-premises apps and data
!! Anytime Anywhere!!"
link="/dextro"
    />
    <ResponsiveCard
      imageSrc={pabulum}
      title="Pabulum"
      description="Digital Menu – End to End Digital Menu Platform for Restaurants.
The PABULUM framework consists of various components that come together to optimize revenues, enhance operations and reduce operational costs . ease of use & it is necessary ."
link="/pabulum" />
    <ResponsiveCard
      imageSrc={hospo}
      title="Hospo"
      description="Achieving Operational Efficiency & Optimal Revenue
The HOSPO is a modern solution that enables medical facilities to overcome the biggest challenges in healthcare delivery today. It is created with a special focus on affordability, scalability and ease-of-use."
link="/hospo"
/>
    <ResponsiveCard
      imageSrc={bithome}
      title="Bit Home"
      description="Home automation, at the touch of a button Bit-home is one of the best Home Automation Companies in India with our expert installers, we integrate perfect smart home systems (home automation systems) for any home size! Time to bring your expression to life."
    link="bitHome"
    />
  </div>
   


               
</section>
    
    </div>
  )
}

export default Product
