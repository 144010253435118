import React from 'react';
import { wdd,userfriend,scalability,uniquebrand,websitedevelopment,webdesign,webapp} from "../../../assets/index"




const WebAppDevelopment = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Web App Development</p>
      <div className='flex items-center justify-center rounded'>
        <img src={wdd} alt='Web App Development' className='rounded-2xl' loading="lazy"/>
      </div>
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center mt-8">Custom Web Development Services</p>
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">
      We develop custom web applications that fit any screen size. No matter what challenges your business faces, we solve them with end-to-end web app development .</p>
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">About Our Web Development Services</p>
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
     
        <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">User-Friendliness</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">The success of any business is highly dependent on the usability of a website. By providing your users with a seamless experience, you keep their attention and increase the conversion rate. Bitblue team always takes a user-first approach to website development services and ensures your audience is delighted with the solution.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={userfriend} alt='Resource Management' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
            <div className="col-span-2  text-center">
                
               
                <img src={scalability} alt='Scalable Product' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
             
            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Scalability</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Building a full-fledged product from scratch might appear to be quite a resource-consuming endeavor, especially if you run a startup and need to bring your product to market as quickly as possible. Why don’t you start with an MVP? We can create a minimum version of your product and scale it later based on the user feedback.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                </div>
            </div>

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Unique Brand Identity</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">There are more than 1.7 billion websites on the net, and most of them look similar. To make your business stand out in such a competitive digital landscape, you’ll need an unconventional and catchy design. Our web design company will create your brand identity that will resonate with your target audience.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={uniquebrand} alt='Unique Brand Images' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
        
            
       
            
        </div>
        
        <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Web App Development</p>
        <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Services We Provide</p>
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">

        
        <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Website Development</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Website is what your customers see when they first interact with your company. With our top-notch website designing and development services, you can be sure your website will be easy to browse and navigate. Our team of web developers combines interactive components with a SEO-optimized design to deliver exceptional web experiences.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={websitedevelopment} alt='Cross-Platform App Development' style={{width:'100%',height:'auto'} } loading="lazy"/>
            </div>

            <div className="col-span-2   text-center">
                
               
                <img src={webdesign} alt='Cross-Platform App Development' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Website Design</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Did you know that the majority of your users access the website from smartphones and tablets? Make sure your product looks appealing on all screens and devices with our responsive and adaptive web design services.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
          

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Web Applications</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">We use the power of innovation and leverage the most robust technologies in the industry to provide you with high-quality web application development services. Web products developed by the LISLINK’s team of experts appeal to your target audience as they are built based on profound market research and target audience analysis.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
            <div className="col-span-2   text-center">
                
               
                <img src={webapp} alt='Cross-Platform App Development' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
       


        </div>
        {/* <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
        <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p>
         */}

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default WebAppDevelopment;
