import React from 'react'
import {hero,gurukulglobal,lncollege,mountcaramel,nirmala,sparlekids,stxavierkalyan,tvm,varma,ymaca,ceo,chief,officer} from "../assets/index"
import Card from './products/Card'
import AboutIndex from './aboutus/AboutIndex'
import Serviceindex from './ourservices/Serviceindex'
import Testimonial from './Testimonial'
import {SiTeamspeak} from 'react-icons/si'
import ContinuousMarquee from './ContinuousMarquee';
import { Link } from 'react-router-dom'
// import Mainreview from './Mainreview'
import { motion } from 'framer-motion';



const Home = () => {

  
    return ( 
      <div>
        <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
            <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Building digital <br/>products & brands.</h1>
                <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">We Deliver innovative Technology Strategies that solve business problems .</p>
                <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link to="/contactus" className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                       
                         
                           Contact us
                    </Link> 

                </div>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <motion.img  whileHover={{ scale: 0.9 }} src={hero} alt="hero" loading="lazy"/>
            </div>                
        </div>
    </section>


<div style={{marginBottom:'10%'}}>
<div className='text-center text-4xl font-bold mb-4 flex itmes-center justify-center 'style={{color:'#0A66C2'}}>Our Clients &nbsp;&nbsp;<motion.div whileHover={{ scale: 0.8 }} ><SiTeamspeak color="red"/>  </motion.div>      </div>
   
    <ContinuousMarquee speed={30000}>
        {/* Your content to be continuously scrolled goes here */}
        
        <div className='flex flex-row items-center justify-center gap-1'>
        <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={gurukulglobal} alt="gurukul" style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }} src={lncollege} alt="lncollege" style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }} src={sparlekids} alt="sparklekids" style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={mountcaramel} alt="mountcaramel" style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={tvm} alt="tvm" style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={varma } alt="varma " style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={stxavierkalyan} alt="stxaviers" style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={ymaca} alt="ymaca " style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                <div className="flex items-center lg:justify-center">
                 <motion.img  whileHover={{ scale: 0.8 }}  src={nirmala} alt="nirmala " style={{width:'200px',height:'auto'}} loading="lazy"/>
                </div>
                </div>
                



      </ContinuousMarquee>
  
    </div>


   <Card/>
   <AboutIndex/>
   <Serviceindex/>

   <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                <h2 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 dark:text-white">OUR TEAM </h2>
                <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Our exceptional team consistently delivers outstanding and awe-inspiring work, setting new standards for excellence and innovation.</p>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              
                <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                  <img src={ceo} alt="officer" loading="lazy"/>
                  <div>
                    <h1 className="text-2xl font-bold"> Mr.Suresh Choudhary</h1>
                    <p className='dark:text-white'>Founder</p>
                  </div>
                    
                  
                    
                </div>
              
                <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <img src={chief} alt="officer" loading="lazy"/>
                <div>
                    <h1 className="text-2xl font-bold">Ms.Tejal Malusare</h1>
                    <p className='dark:text-white'>Chief Technology Officer (CTO)</p>
                  </div>
                  
                  
                </div>
               
                <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <img src={officer} alt="officer" loading="lazy"/>
                <div>
                    <h1 className="text-2xl font-bold">Mr. Jitendra Choudhary</h1>
                    <p className='dark:text-white'>Marketing Director</p>
                  </div>
                  
                </div>
                
             
            </div>
        </div>
    </section>






   <Testimonial/>
   {/* <Mainreview/> */}
{/* <Reviews/>   */}



  
 


        </div>
    )
}

export default Home