import React, { useState, useEffect } from 'react';
import {BsFillArrowUpCircleFill }from 'react-icons/bs'
// Import your CSS file for styling

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show the ScrollToTop button when the user scrolls down a certain distance
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible &&
        <div className="scroll-to-top-btn" onClick={scrollToTop}>
          <BsFillArrowUpCircleFill size={20}/>
        
        </div>
      }
    </div>
  );
};

export default ScrollToTop;
