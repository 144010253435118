import React from 'react';
import {bitpay,bitpay2,bitpay3 }from "../../../assets/index"




const Bitpay = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
 <div className='flex items-center justify-center flex-row mb-4'><span className='dark:text-white text-gray-700' style={{fontFamily: "'Pacifico', cursive", fontSize: '3rem'}} >Bitpay</span> </div>
      {/* <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Dextro </p> */}
      <div className='flex items-center justify-center rounded'>
        <img src={bitpay} alt='restaurant' className='rounded-2xl' style={{width:'40%',maxHeight:'450px',height:'auto'}} loading="lazy"/>
      </div>
      <p style={{fontSize:'18px'}} className="mt-10 font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">Having been around for over a decade, Basecamp is considered a reliable tool that excels at giving organizations a high-level view of their teams. Like Asana, Basecamp can help monitor tracking, but also offers additional features like direct messaging chats, centralized document storage, and a scheduling tool.Basecamp aims to take on Slack, Asana, Google Drive, and Dropbox by melding all of their competitors into one robust management tool.</p>
      
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Features </p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white ">Designed with the harried business person in mind, Basecamp helps managers and team members stay on top of their professional lives. The app boasts that users will no longer drown in a sea of emails as that feature is already embedded into the app. Additionally, the scheduling and tracking features help ensure teams never again miss a deadline. Another interesting component of Basecamp is that managercan eliminate the need for “check-in” meetings by sending an automated message daily to employees that ask for a recap of what they accomplished that day. Then employees can “tag” teammates in their recaps to explain what they need help with or what they finished.</p>
   
      
      
      
        <div className="items-center max-w-screen-xl px-4  mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24  lg:px-6">
     
     <div className="col-span-2 mb-8">
     <p className="text-3xl mb-4 font-bold  text-start dark:text-white"> Cost</p>
             <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">A unique feature of Basecamp is that the app doesn’t charge for an increase in the number of users or projects. So unlike some of its peers, Basecamp charges a flat-fixed fee of $99 a month for a team, no matter the size.</p>
           
         </div>
     <div className="col-span-2   text-center">
             
            
            <img src={bitpay2} alt='bitpay2' style={{width:'100%',height:'auto'}} loading="lazy"/>
         </div>
         <div className="col-span-2   text-center">
             
            
             <img src={bitpay3} alt='bitpay3' style={{width:'100%',height:'auto'}} loading="lazy"/>
          </div>
         
         <div className="col-span-2 mb-8">
         <p className="text-3xl mb-4 font-bold  text-start dark:text-white"> Why It’s Good for SEO Pros</p>
     
             <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">Managing a client’s SEO consists of many different timelines and action items. Keeping track of client emails, meetings, and central documents is a full-time job.</p>
             <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">By offering one of the best all-encompassing software solutions, Basecamp helps busy SEO pros stay on top of their entire business by more efficiently checking in with their team and deadlines in one easy to use the app.Casie Gillette, senior director of digital marketing at KoMarketing, uses Basecamp mainly for communicating to clients.</p>
             <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">“Basecamp is our primary means of communication with clients,” Gillette said. “For any deliverable, it allows an easy way to track the conversation and adjust docs accordingly.”</p>
         </div>
    
       
      </div>
      

      

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default Bitpay;


