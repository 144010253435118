import React from 'react';


import {digitalmarketting,digital2 }from "../../../assets/index"







const DigitalMarketting = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
 <div className='flex items-center justify-center flex-row mb-4'><span className="dark:text-white text-gray-700" style={{fontFamily: "'Pacifico', cursive", fontSize: '3rem'}} >Digital Marketting</span> </div>
      {/* <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Dextro </p> */}
      <div className='flex items-center justify-center rounded'>
        <img src={digitalmarketting} alt='restaurant' className='rounded-2xl' style={{width:'40%',maxHeight:'450px',height:'auto'}} loading="lazy"/>
      </div>
      <p style={{fontSize:'18px'}} className="mt-10 font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">If you're trying to draw attention to your business and boost your brand's visibility, then you're in the right spot! Our engaging and visually appealing-focused ads will be the most effective way to establish and convey your message to the correct group of people! As you are aware that businesses are expanding using digital platforms at a rapid rate, and to stand out from your competition and make a distinctive mark on the market, the most effective method to use is digital marketing. This Best Digital Marketing Company in Mumbai can help you gain internet visibility, traffic to your website, lead generation, an impressive social media presence on the marketplace, and much more. </p>
      
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Why Us ? </p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">Bitblue technology , offers the most effective SEO, SEM, PPC, and SMO services. Today SEO, or Search Engine Optimization, is among the essential aspects of any online company's marketing.</p>
   
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">The two primary outcomes of SEO are growing recognition and being placed on the top page of Google searches by people worldwide. Bitblue Technology is an expert in online marketing in the fields of Digital Marketing, Social Media, SEO, and Web Analytics. Along with time, we've also gained knowledge in the other promotion techniques for businesses, such as email marketing, Search Engine Marketing, Social Media Marketing, and Content Marketing, as well as event marketing and Mobile Marketing. Search Engine Optimization gives the best outcomes in terms of investment return when compared with other advertising methods using media. We will optimize your website for low cost and with the best results.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white ">We are a group of professionals that utilize the latest methods to ensure your site is ranked on Google and help increase the visibility of your online presence. We utilize a variety of methods to promote your website, such as social media, and keyword ranking, for example. After simply implementing digital marketing on your site. We will send you a detailed analysis of the work we have done, and you will be able to notice the difference. We offer our 100 percent and ensure our clients are happy with our service. Bitblue always does its best to keep and strengthen our relationships with our customers. Join us and get our best service to offer to you.</p>

      
      
        <div className="items-center max-w-screen-xl px-4  mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24  lg:px-6">
     
     <div className="col-span-2 mb-8">
          
             <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400 mb-4">We strongly believe in the concept of partnerships and our clients are our partners. We grow when our partners grow. So we are on this journey together! The success of any one of us is the success of all of us. It is important to create relationships, spaces and situations where we can make brave choices</p>
                
             



           
         </div>
     <div className="col-span-2   text-center">
             
            
            <img src={digital2 } alt='Digital Marketting' style={{width:'100%',height:'auto'}} loading="lazy"/>
         </div>
       
      </div>

      

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default DigitalMarketting;

