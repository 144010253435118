import React, { useState } from "react";
import { title_icon } from "../assets/index";
import axios from "axios";
// import ReCAPTCHA from 'react-google-recaptcha';

const Career = () => {
  // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [formData, setFormData] = useState({
    candidate_name:"",
    position: "",
    gender: "",
    dob: "",
    phone_number: "",
    email: "",
    qualification: "",
    programming_languages: "",
    message: "",
    candidate_image: null,
    upload_resume: null,
  });


      const handleChange = (e) => {
    const { name, value, type } = e.target;
    const updatedFormData = { ...formData };
    updatedFormData[name] = type === 'file' ? e.target.files[0] : value;
    setFormData(updatedFormData);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ["candidate_name", "position", "gender", "dob", "phone_number", "email", "qualification"];
    const missingFields = requiredFields.filter((field) => !formData[field]);
    if (missingFields.length > 0) {
      alert(`Please fill in all required fields: ${missingFields.join(", ")}`);
      return;
    }
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });
    
  

    try {
      const response = await axios.post(`https://bitbluetech.com/career`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Form submitted successfully!");
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div className=" p-6">
      <section className="flex items-center justify-center ">
        <div >
          <div className="flex items-center gap-4 flex-col">
           
            <p className="text-4xl font-bold dark:text-white">Join Our Team</p>
            <div className="text-xl">
              Do you find a passion in solving business problems utilizing
              modern technolgies ?
            </div>
            <div className="text-xl">
             
              We are building products for future with a mission to tap the
              mobile market .Come Join Us!!
            </div>
          </div>

          <div className="flex flex-wrap items-center ">
            <div
            
              className="flex justify-center items-center flex-row "
            >
              <img
                src={title_icon}
                alt="title-icon"
                style={{ height: "20px" }}
                loading="lazy"
              />
              <div className="text-xl text-gray-600 uppercase font-bold flex items-center justify-center text-center ">
           <p className="dark:text-white">    We are always looking for people who can make a difference at
                Bitblue and are passionate about technology. If you don't find
                any open position below, you can still apply with your resume.</p> 
              </div>
              <img
                src={title_icon}
                alt="title-icon"
                style={{ height: "20px" }}
                loading="lazy"
              />
            </div>
            <div className=" p-4">

              <form onSubmit={handleSubmit} encType="multipart/form-data"  className="flex flex-wrap  ">
              
                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <label>Candidate Name</label>
                    <input
                     required 
                     autoComplete='off'
                      type="text"
                      name="candidate_name"
                      value={formData.candidate_name}
                      onChange={handleChange}
                      placeholder="Candidate Name"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                    />
                  </div>
                   <div className="w-full sm:w-1/2 p-2 mb-4">
                    <label>
                      Candidate Image
                      <span className="text-red-600">
                        (Please upload documents only in 'jpeg', 'jpg' png.)
                      </span>
                    </label>
                    <input
                      required 
                    
                      type="file"
                      name="candidate_image"
                      onChange={handleChange}
                      // onChange={saveFile}
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white dark:text-white text-gray-800 border border-gray-200 rounded"
                      style={{ lineHeight: "20px" }}
                      accept=".jpeg,.jpg,image/*,.png"
                      
                    />
                  </div> 

                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <div className="mb-4">
                      <label>Apply for</label>
                      <br />
                      <select
                        className="rounded border border-gray-200"
                        name="position"
                        value={formData.position}
                      onChange={handleChange}
                        required
                        autoComplete='off'
                        style={{ width: "100%", height: "50px" }}
                      >
                        <option value="">Select Position</option>
                        <option value="Software Developer">
                          Software Developer
                        </option>
                        <option value="Software Tester">Software Tester</option>
                        <option value="Hardware/Network Engineer">
                          Hardware/Network Engineer
                        </option>
                        <option value="Marketing Executive">
                          Marketing Executive
                        </option>
                        <option value="Technical Support Engineer">
                          Technical Support Engineer
                        </option>
                        <option value="Social Media Marketing Executive">
                          Social Media Marketing Executive
                        </option>
                        <option value="Graphic Designer">
                          Graphic Designer
                        </option>
                        <option value="Business Developer">
                          Business Developer
                        </option>
                        <option value="Sales Executive">Sales Executive</option>
                        <option value="Android Developer">
                          Android Developer
                        </option>
                        <option value="Accountant">Accountant</option>
                      </select>
                    </div>

                  </div>

                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <div className="mb-4">
                      <label className="control-label" htmlFor="gender">
                        Select Gender
                      </label>
                      <br />
                      <select
                       autoComplete='off'
                        className="rounded border border-gray-200"
                        name="gender"
                        value={formData.gender}
                      onChange={handleChange}
                        required
                        style={{ width: "100%", height: "50px" }}
                      >
                        <option value="">Select your Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <div className="mb-4">
                      <label htmlFor="dob">Date of birth</label>
                      <input
                       autoComplete='off'
                        type="date"
                        name="dob"
                        value={formData.dob}
                      onChange={handleChange}
                        className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      />
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <label>Phone number</label>
                    <input
                     autoComplete='off'
                      type="number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                      placeholder="Your Phone"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      required
                    />
                  </div>

                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <label>Email</label>
                    <input
                     autoComplete='off'
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      required
                    />
                  </div>

                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <label>Qualification</label>
                    <input
                     autoComplete='off'
                      type="text"
                      name="qualification"
                      value={formData.qualification}
                      onChange={handleChange}
                      placeholder="eg: BTech / BE"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                    />
                  </div>

                  <div className="w-full sm:w-1/2 p-2 mb-4">
                    <label>Programming languages Known</label>
                    <input
                     autoComplete='off'
                      type="text"
                      name="programming_languages"
                      value={formData.programming_languages}
                      onChange={handleChange}
                      placeholder="eg: PHP / SQL etc"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                    />

                    <p className="danger"></p>
                  </div>

                 <div className="w-full sm:w-1/2 p-2 mb-4">
                    <div className="mb-4">
                      <label htmlFor="resume">
                        Upload resume
                        <span style={{ color: "red" }}>
                          (Please upload documents only in 'pdf', 'docx'
                          format.)
                        </span>
                      </label>
                      <input

                        type="file"
                       name="upload_resume"
                       onChange={handleChange}
                        // onChange={saveFile}
                        accept=".docx,.pdf"
                        className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                        required
                      />
                    </div>
                  </div> 

                  <div className="w-full p-2 mb-4">
                    <label htmlFor="resume">Message</label>
                    <textarea
                     autoComplete='off'
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Message"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                    ></textarea>
                  </div>
                  {/* <div className="lg:w-1/2 pr-4 pl-4 md:w-1/2 pr-4 pl-4 sm:w-full pr-4 pl-4 mt-3 ">
                  <ReCAPTCHA sitekey="6LdS3oMjAAAAALcWEPSydgLzCS1CV14VRouvsFml" onChange={handleCaptchaChange} />
                    
                  </div> */}

                  <div className="w-full text-center p-2 mb-2">
                    <button
                      type="submit"
                      className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-purple-500 hover:to-blue-500 text-white px-4 py-2 rounded-md shadow-md"
                    >
                      Submit Now
                    </button>
                  </div>
                
              </form>

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Career;
