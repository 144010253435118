import React from 'react';
import {scalable,unique,cross,ios,android,rm} from "../../../assets/index"



const MobileAppDevelopment = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Mobile App Development</p>
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Whenever you are looking to leverage the power of mobile app development solutions, our experts are here to support you with this task. Modern technologies help businesses across all domains to grow revenues, win new competitive advantages and stand out with their products or services. It is exactly what our qualified and experienced mobile app developers do. BITBLUE TECHNOLOGY  app development company creates products that win the hearts and minds of your customers.</p>
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center mt-8">About Our App Development Services</p>
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
     
        <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Efficient Resource Management</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">When you reach out to us for quality mobile app development services, we carefully analyze your needs and come up with the most optimal solution that perfectly aligns with your business goals and budget. Thus, you can be sure that applications developed by BITBLUE mobile development agency are always time and money-efficient.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Planning and Forecasting.
                        
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Sustainability and Environmental Considerations.
                         
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Training and Skill Development.
                         
                      </div>
                    </div>
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={rm} alt='rm' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
            <div className="col-span-2  text-center">
                
               
                <img src={scalable} alt='Scalable Product' style={{width:'90%',height:'auto'}} loading="lazy"/>
             </div>
             
            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Scalable Products</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Leaving room for improvement is essential when it comes to mobile app development. Even if your product is perfect, the time comes when you’ll need to integrate new features to meet your  needs and keep up with the latest trends. BITBLUE TECHNOLOGY app development services help you to create products that are easy to scale and maintain..</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    
                </div>
            </div>

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Unique Visual Design</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">There are more than 3 million applications in the App Store, and this figure continues to grow. In such a saturated market, it is often challenging to stand out and attract your audience. What can grab attention better than a bright and appealing interface? Create your app with the best UI/UX designers to outpace your competitors.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={unique} alt='Resource Management' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
        
            
       
            
        </div>
        
        <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Mobile App Development</p>
        <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Services We Provide</p>
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">

        
        <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Cross-Platform App Development</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Cross-platform applications often serve as a unique and cost-effective solution for companies. A single codebase, ease of maintenance, reduced development costs are just a few benefits provided by cross-platform solutions. BITBLUE’s mobile application development services empower businesses and boost their performance.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={cross} alt='Cross-Platform App Development' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>

            <div className="col-span-2   text-center">
                
               
                <img src={ios} alt='Cross-Platform App Development' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">iOS Mobile App Development</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">iPhone products are famous for their quality and elegant design. Apple’s followers value premium quality they get, which means they are not ready to comprise it with application development services. If you are looking for a mobile app development company to launch your next iOS app, rely on BITBLUE experts. With vast expertise in the field, we will create software to meet your needs.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
          

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Android Mobile App Development</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Android is the most widespread operating system in the world. With 2.5 billion active Android https://bitbluetech.com/assetss, you have a great chance to extend your reach with our custom application development. BITBLUE experienced developers know how to make your app appealing and fascinating to the target audience.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                   
                </div>
            </div>
            <div className="col-span-2   text-center">
                
               
                <img src={android} alt='Cross-Platform App Development' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
       


        </div>
        {/* <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
        <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p>
         */}

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default MobileAppDevelopment;
