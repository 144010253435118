import React from 'react'
import {title_icon} from "../assets/index";

const Testimonial = () => {
  return (
    <div>
         <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
            <figure className="max-w-screen-md mx-auto">
            <div className='items-center flex flex-row justify-center'><img src={title_icon} alt='titleIcon' loading="lazy"/>&nbsp;&nbsp;<h1 className='text-center font-bold text-2xl'>REAL TESTIMONIALS</h1>&nbsp;&nbsp;<img src={title_icon} alt='titleIcon' loading="lazy"/></div> 
                
                <blockquote>
                    <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">What They Say About Our Company?</p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                   
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <div className="pr-3 font-medium text-2xl text-gray-900 dark:text-white">" We know how important customer experience is for a business and therefore,
we trive to make your company excel in this ."</div>
                        
                    </div>
                </figcaption>
            </figure>
        </div>
    </section>
    </div>
  )
}

export default Testimonial
