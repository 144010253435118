import React from 'react';

import {bithome,bithome1} from "../../../assets/index"





const Bithome = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
 <div className='flex items-center justify-center flex-row mb-4'><img src={bithome} alt='hospo' style={{width: '80px',height:'auto'}} loading="lazy"/><span className="dark:text-white text-gray-700" style={{fontFamily: "'Pacifico', cursive", fontSize: '3rem'}} >BitHome</span> </div>
      
      
      <div className='flex items-center justify-center rounded'>
        <img src={bithome1} alt='restaurant' className='rounded-2xl' style={{width:'40%',maxHeight:'450px',height:'auto'}} loading="lazy"/>
      </div>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">Home automation, at the touch of a button Connect your home with the latest technology and experience a smarter living space - seamless automation that maximises your time and minimises effort. </p>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">Take complete control over your home from lighting & shading, audio visual, climate control and security systems. Time to bring your expression to life.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">We specialise in making your home life more convenient, comfortable and enjoyable with the power of home automation. Having worked with smart home automation systems for many years, we are experts on maximising the impact of your residential technology and designing a smart home which is tailored exclusively to you. </p>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">We install industry leading smart home systems such as Control4 and Savant which allow you to control, synchronise and schedule all of the technology in your home from one user-friendly app. Play your favourite music throughout the house, control the lighting and heating in each room, program your blinds to open and close, answer the front door remotely… The possibilities are endless and can all be controlled with the touch of a button.</p>
       
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Services we Provide </p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white ">Creative Rooms offer the complete smart home package; from the initial design of the system, to the cabling and installation of hardware and finally the programming and automation of your home. We also offer an unrivalled after-care support service which ensures that your home automation system continues to work seamlessly and adapts to your ever-changing lifestyle. </p>

      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white">1. Home Cinema Installation</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white ">We specialise in the design and installation of luxury home cinema rooms. No matter the size or shape of your room, our team of experts will create a completely bespoke solution, allowing you to enjoy the magic of cinema in your own home. </p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 2.Multi-Room AV</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white ">We work with industry leading suppliers such as Artcoustic, Sonance and Bowers & Wilkins to install crisp and punchy multi-room sound systems that blend seamlessly with your home’s aesthetic. Our range of products include recessed ceiling speakers, plaster-over hidden wall speakers, waterproof garden speakers and much more.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white">3. Smart Lighting Control</p>

      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">Smart lighting control allows multiple light circuits to be programmed and controlled in a simple and intelligent way. Create custom commands that allow you to turn on/off multiple lights with a single tap or define and save your favourite scenes in each room depending on how you like it.</p>
      <p className="text-3xl mb-4 font-bold  text-start dark:text-white"> 4. Automated Blinds</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">Smart home automation allows you to control your blinds remotely and synchronise their movement with other functions in the home. In a cinema room for example, simply touch a button and the lights could dim, the blinds lower and the screen and speakers come to life. Combining automated blinds with lighting control can also lead to a more energy efficient home.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 5. CCTV and Security</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">Live streaming CCTV footage is simply not enough to give the homeowner the peace of mind that they need nowadays. Home automation tackles security at the source and minimises the risk of any danger by putting good security practices in place for you. Alarm systems, entry systems, CCTV recording and number plate recognition can all be integrated into your smart home system for a complete security solution.</p>
    
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white">6. Home Network Installation</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">We believe that internet connection should not have to be a consideration in the modern home. This is why we take such great pride in the design and installation of hassle-free home networks. We guarantee that our home networks will give you unlimited connectivity wherever you may need it and have zero impact on how you would usually connect and stay connected to the internet.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white">7. Heating Control</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start dark:text-white">Our systems are fully customisable and allow complete control over your home surroundings. These smart thermostat can learn what temperature you like at different times of day and night and with an in-home system, you can control your heating or cooling as well as monitor its usage .</p>

      
        
        
        

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default Bithome;
