import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/style/Style.css'
import { motion } from 'framer-motion';

const ResponsiveCard = ({ imageSrc, title, description,link }) => (
  <div className="open"
  style={{
    width: '300px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign:'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px',
    overflow: 'hidden',
    display: 'flex',
  justifyContent:' center',
  flexDirection:'column', /* Horizontal center */
  alignItems: 'center',
  }}>
  
    <motion.img src={imageSrc} alt={title} loading="lazy"  style={{
      width: '40%',
      height: 'auto',
      objectFit: 'cover',
    }} 
    whileHover={{ scale: 0.8 }}
    />
    
    
    <div style={{ padding: '16px' }}>
      <h3 className="dark:text-gray-900"  style={{ margin: 0, padding: 0, fontSize: '1.5rem', textAlign:'center' }}>{title}</h3>
      <p className="dark:text-gray-700" style={{color:'#000;'}}>{description}</p>
    </div>
    <Link to={link}>
    <button className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
      >
  See More
</button>
    {/* <label class="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded bottom-0">
  See More
</label> */}
 </Link>
  </div>
);

export default ResponsiveCard;