import React, { useState } from 'react'
import {title_icon} from "../assets/index"
import {TiStopwatch} from "react-icons/ti"
import {VscLocation} from "react-icons/vsc"
import {LiaPhoneVolumeSolid} from "react-icons/lia"
import {GrFacebook} from "react-icons/gr"
import {FaTwitterSquare} from "react-icons/fa"
import {BsLinkedin} from "react-icons/bs"
import {IoLogoYoutube} from "react-icons/io"
import {FaSquareInstagram} from "react-icons/fa6"
import axios from 'axios'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.phone || !formData.email || !formData.subject || !formData.message) {
      alert('Please fill in all required fields.');
      return;
    }
  
    const data = new FormData();
    data.append('name', formData.name);
    data.append('phone', formData.phone);
    data.append('email', formData.email);
    data.append('subject', formData.subject);
    data.append('message', formData.message);


    try {
      await axios.post(`https://bitbluetech.com/contactus`, data, {
        headers: {
          'Content-Type': 'application/json',
         
        }
      });
      alert('Form submitted successfully!');
    
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };


  
  return (
    <div >
      <section className="flex items-center justify-center ">
    <div className=" ">
      
        <div className="flex items-center gap-4 flex-col">
            <h1 className='text-3xl font-bold text-gray-600 uppercase flex items-center gap-5 flex-row'><img src={title_icon} alt='title-icon' loading="lazy"/> FOLLOW OUR INFO<img src={title_icon} alt='title-icon' loading="lazy"/></h1>
            <h2 className='text-4xl font-bold'>Contact Information</h2>
            <div className="text-xl p-4">Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days.
We will be happy to answer your questions.</div>
       
        </div>


        <div className="col-span-2 p-5 py-20 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">


                <div
                whileHover={{scale:0.8}}
                 className="flex item-center justify-center flex-row" >

                   <motion.div whilehover={{scale:0.8}} className=' p-5  bg-gray-100 rounded-full'><TiStopwatch  size={60} color="#bb0b15"/></motion.div>
                   <div className='flex item-center justify-center flex-col p-2'>
                    <h3 className="text-2xl font-bold dark:text-white">09:00am to 6:30pm</h3><br/>
                    <p className="font-light text-gray-500 dark:text-gray-400">Sunday Closed.</p>
                    </div>


                </div>
                <div className="flex item-center justify-center flex-row" >

                <motion.div whilehover={{scale:0.8}}  className=' p-5 bg-gray-100 rounded-full'><VscLocation  size={60} color="#bb0b15"/></motion.div>
                  
                    <div className='flex item-center justify-center flex-col'>
                   
                    <p  className="p-5 dark:text-white">G1, Oswal Nagri, Building No. 2A,
                                   Opp. Muthoot Finance, Next to Central Park,
                                      Nalasopara East - 401209.</p>
                       </div>



                </div>


                <div className="flex item-center justify-center flex-row"  >
                
                 <motion.div whilehover={{scale:0.8}} className=' p-5  bg-gray-100 rounded-full'><LiaPhoneVolumeSolid  size={60} color="#bb0b15"/></motion.div> 
                 <div className='flex item-center justify-center flex-col p-2 '>
                    <h3 className=" text-2xl font-bold dark:text-white">+918828020600</h3><br/>
                    <p className="font-light text-gray-500 dark:text-gray-400">contact@bitbluetech.com</p>
                   
                 </div> 


                </div>



                <div className="flex item-center justify-center flex-row" >
                <div className='className="flex item-center justify-center flex-col" '>
                
                    <h3 className="text-2xl font-bold dark:text-white">Our Social Media Links</h3>
                    <p className="dark:text-white">Print this page to PDF for the complete set of vectors.</p>
                    <div className='flex items-start gap-4 flex-row text-start'>
                     <Link to="https://www.facebook.com/Bitbluetech/" target="_blank"> <GrFacebook  size={44} color="#1877F2" /></Link>
                     <Link to="https://twitter.com/Bitbluetech01" target="_blank"><FaTwitterSquare  size={48} color="#1DA1F2" /></Link> 
                     <Link to="https://www.linkedin.com/profile/view?id=AAIAABp1IgQBWW6B--9JineoON_6Esqf-n8mP4I&trk=nav_responsive_tab_profile" target="_blank"> <BsLinkedin  size={44} color="#0A66C2" /></Link>
                     <Link to="https://www.youtube.com/channel/UCc5Jlj24LE5cUThIaVihREA" target="_blank"><IoLogoYoutube  size={48} color="#FF0000" /></Link>
                     <Link to="https://www.instagram.com/bitbluetechnology1/" target="_blank"><FaSquareInstagram  size={48} color="#E4405F"  /></Link>
                    
                    </div>
                    </div>
                </div>
            </div>




        <div className="flex flex-wrap items-center p-4">
        <iframe
                title='map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.6865411656904!2d72.8155429743429!3d19.425945040895083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a968f74946d1%3A0x3c519eb00d53168c!2sBitblue%20Technology!5e0!3m2!1sen!2sin!4v1690521275838!5m2!1sen!2sin"
                width="100%"
                height="450"
                className="rounded"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>

        </div>
    </div>
    

</section> 
<section  className="flex items-center justify-center">
  
<div className="container py-10">
      
      <div className="flex items-center gap-4 flex-col">
          <h1 className='text-3xl font-bold text-gray-600 uppercase flex items-center gap-5 flex-row'>
          <img src={title_icon} alt='title-icon' loading="lazy"/> DON't HESITATE TO CONTACT WITH US<img src={title_icon} alt='title-icon' loading="lazy"/></h1>
          <h2 className='text-4xl font-bold'>Now Very Easy</h2>
          <div className="text-xl">For more information on our products, services, or careers, please fill this form.</div>
     
      </div>
      <div className="contact-form container w-full  flex items-center flex-wrap justify-center gap-6 p-5">
      
<form className='w-full' onSubmit={handleSubmit}>
  <div className="flex cols-2 gap-10 p-4 ">
    <input type="text"  name="name" value={formData.name} onChange={handleChange} autoComplete='off'
     className="bg-gray-50 border border-gray-300 hover:border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" required />
    <input type="number"  name="phone" value={formData.phone} onChange={handleChange} autoComplete='off'
    className="bg-gray-50 border border-gray-300 hover:border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Phone" required />
  </div>
  <div className="flex cols-2 gap-10 p-4">
    <input type="email"  name="email" value={formData.email} onChange={handleChange} autoComplete='off'
     className="bg-gray-50 border border-gray-300 hover:border-red-600  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Email" required />

    <input type="text"  name="subject" value={formData.subject} onChange={handleChange} autoComplete='off'
    className="bg-gray-50 border border-gray-300 hover:border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="subject" required />
  </div>
  <div className="flex mb-8  p-4">
    <textarea type="text"  name="message"  value={formData.message} onChange={handleChange} autoComplete='off'
    className="bg-gray-50 border border-gray-300 hover:border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Message" required />

  </div>
  
    <div className='flex items-center justify-center'>
  <button type="submit"  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
  </div>
</form>


              
              
                
            </div>
      </div>
</section>
    </div>
  )
}

export default Contactus
