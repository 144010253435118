import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./component/Header";
import Home from "./component/Home";
import About from "./component/aboutus/About";
import Career from "./component/Career";
import Contactus from "./component/Contactus";
import Footer from "./component/Footer";
import MobileAppDevelopment from "./component/ourservices/pages/MobileAppDevelopment";
import WebAppDevelopment from "./component/ourservices/pages/WebAppDevelopment";
import Softwaredevelopmentconsulting from "./component/ourservices/pages/Softwaredevelopmentconsulting";
import ProductDesign from "./component/ourservices/pages/ProductDesign";
import Machine from "./component/ourservices/pages/Machine";
import Bithome from "./component/products/pages/Bithome";
import Bitpay from "./component/products/pages/Bitpay";
import Dextro from "./component/products/pages/Dextro";
import DigitalMarketting from "./component/products/pages/DigitalMarketting";
import Hospo from "./component/products/pages/Hospo";
import Pabulum from "./component/products/pages/Pabulum";
import ScrollToTop from "./component/ScrollToTop";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/about" element={<About />} />
      </Routes>
      <Routes>
        <Route
          path="/mobileappdevelopment"
          element={<MobileAppDevelopment />}
        />
      </Routes>
      <Routes>
        <Route path="/WebAppDevelopment" element={<WebAppDevelopment />} />
      </Routes>
      <Routes>
        <Route
          path="/Softwaredevelopmentconsulting"
          element={<Softwaredevelopmentconsulting />}
        />
      </Routes>
      <Routes>
        <Route path="/ProductDesign" element={<ProductDesign />} />
      </Routes>
      <Routes>
        <Route path="/Machine" element={<Machine />} />
      </Routes>
      <Routes>
        <Route path="/Bithome" element={<Bithome />} />
      </Routes>
      <Routes>
        <Route path="/Bitpay" element={<Bitpay />} />
      </Routes>
      <Routes>
        <Route path="/Dextro" element={<Dextro />} />
      </Routes>
      <Routes>
        <Route path="/Pabulum" element={<Pabulum />} />
      </Routes>
      <Routes>
        <Route path="/DigitalMarketting" element={<DigitalMarketting />} />
      </Routes>
      <Routes>
        <Route path="/Hospo" element={<Hospo />} />
      </Routes>
      <Routes>
        <Route path="/career" element={<Career />} />
      </Routes>
      <Routes>
        <Route path="/contactus" element={<Contactus />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
