import React from 'react';

import {dxerp,dextro,dxsms }from "../../../assets/index"



const Dextro = () => {
  return (
    <div className='p-10  flex items-center center justify-center flex-col'>
      
      <section className="bg-white dark:bg-gray-900">
 <div className='flex items-center justify-center flex-row dark:text-white'><img src={dextro} alt='dxlogo' style={{width: '80px',height:'auto'}} loading="lazy"/><span className='dark:text-white text-gray-700' style={{fontFamily: "'Pacifico', cursive",  fontSize: '3rem'}} >extro</span> </div>
    
      <div className='flex items-center justify-center rounded'>
        <img src={dxerp} alt='DextroERP' className='rounded-2xl' style={{width:'40%',maxHeight:'450px',height:'auto'}} loading="lazy"/>
      </div>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">Dextro Campus™ is the consortium of Technology, Skills, Methods, and Process used in the Efficient Application of Tech-based services.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-dark-500 sm:text-xl dark:text-white text-start"> Applying user-friendly technology to ensure a smooth transition of traditional institutes to Smart Institutes.</p>
      <p style={{fontSize:'18px'}} className=" font-bold text-dark-500 sm:text-xl dark:text-white text-start"> Our Motive is to bring all the stakeholders of the institute i.e Administrator, Staff, Students, and Parents under One Roof digitally.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-dark-500 sm:text-xl dark:text-white text-start">  In other words, Technology acts as a connecting link between all, facilitating not just communication but two-way communication at fingertips.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-dark-500 sm:text-xl dark:text-white text-start">  Gone are the day where parents had to reach out in person to the institute rescheduling their work, today with us they can know their child’s academic and non-academic performance through the app.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-dark-500 sm:text-xl dark:text-white text-start"> With a primary focus to endure accountability and transparency even in the educational industry which lays the foundation for a strong and resilient India. Assuring an integrated software solution with latest technology but yet user friendly remains the USP of our ERP solutions. Relieving the staff from maintaining heaps of paper work and duplication of work our system generates and analysis reports right from student’s admission to this transference certificate. The student’s entire journey in the institute is preserved and readily available at given point of time.</p>
      <p style={{fontSize:'18px'}} className="font-bold text-dark-500 sm:text-xl dark:text-white text-start"> Similarly, MIS reports can be generated and exported in any format. It’s not only students whose record is maintained but even teaching and non-teaching staff can update their daily reports online facilitating 360- degree appraisals. Faculties and Students are centric to all the activities with more than 50+ features infused in the system. Dextro Campus™ ERP System provides user-friendly dashboards with login access for teachers, non-teaching staff, students, parents and management of the institution. The various modules available in Dextro Campus™ facilitate all the processes of your institution, from admission of new students to generating transfer certificates when students complete their studies Dextro Campus™ ERP has modules to manage Admission, Enquiry, Fee management, Timetable, Attendance, Online Examinations, Result, Dx-room, Library, Placements, Transportation, Events and many more. To summarize it is a Comprehensive, Easy to use Cloud based Institution management system that helps you to move faster, save money, integrate on-premises apps and data. The solutions when optimally used can lead towards improved grades of accreditation.</p>
      
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Our mission</p>
      <p className="text-xl mb-4 font-italic  text-center mt-8 dark:text-white"><i>" Our Mission is to deliver cost-effective and reasonable ERP solutions for educational institutions worldwide. With maximum attention, we aim to provide an outstanding ERP system to educational institutions for enhancing and streamlining their day-to-day tasks. "</i></p>
       
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Our Vision</p>
      <p className="text-xl mb-4 font-italic  text-center mt-8 dark:text-white"><i>" Empower educational services in an ever-changing environment to provide students and instructors with improved possibilities for continual improvement. "</i> </p>



      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">1. We are committed to growing and becoming a market leader in the educational sector by delivering high-quality institutional features. </p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">2. Our goal is to establish a strong chain of educational institutions that can interact and communicate through a single platform.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">3. Our School Management Software upholds the highest ethical standards. It serves the greater good of society by providing superior educational services via the use of new technologies, regardless of the geographical or technological medium. </p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">4. Additionally, our team of expert developers aspires to cultivate meaningful relationships with educational institutions worldwide, to establish a long-term partnership with the organizations. </p>
      
        
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Why us ?</p>
        
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">A perfect school management software has speed, security, accuracy, and a wide range of functions and modules. More than 1300+ schools around the country rely on our comprehensive product and exceptional service. We have a global client base of happy customers. Our school management software uses cloud computing to store data that can be accessed at any time. Also, data backup is automated, so data recovery is not an issue. Save and keep data on decentralized databases does not guarantee data security or recovery. Our school ERP system uses the cloud to store data, saving time and providing advantages like dependability, portability, backup, and high speed. </p>

        
        <div className="items-center max-w-screen-xl px-4  mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
     
        <div className="col-span-2 mb-8">
 
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">By offering one of the best all-encompassing software solutions, Basecamp helps busy SEO pros stay on top of their entire business by more efficiently checking in with their team and deadlines in one easy to use the app.Casie Gillette, senior director of digital marketing at KoMarketing, uses Basecamp mainly for communicating to clients.</p>
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">“Basecamp is our primary means of communication with clients,” Gillette said. “For any deliverable, it allows an easy way to track the conversation and adjust docs accordingly.”</p>
              
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={dxsms} alt='Scool MAnagement System' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
          
         </div>
       

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default Dextro;
