import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { MdContactPhone } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import { FaAddressCard } from "react-icons/fa";
import {logobit} from "../assets/index";
import { Link } from "react-router-dom";
import {BiLogoFacebookCircle,BiLogoTwitter,BiLogoLinkedin,BiLogoYoutube} from 'react-icons/bi'
import { motion } from 'framer-motion';

const Footer = () => {
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div>
      <footer className="bg-white dark:bg-gray-800 center">
        <div className="max-w-screen p-4 py-2 mx-auto lg:py-16 md:p-8 lg:p-10">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-6">
            <div className="col-span-2">
              <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact Info
              </h3>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <div
                    className="flex items-center flex-row"
                    style={{ width: "300px" }}
                  >
                   
                    <BiTime />
                    &nbsp;&nbsp; Mon – Sat 
                    &nbsp; 09:00pm - 6:30am
                  </div>
                </li>
                <li className="mb-4">
                  <div className="flex items-center flex-row">
                    <MdContactPhone />
                    &nbsp;&nbsp; +91 8828020600
                  </div>
                </li>
                <li className="mb-4">
                  <div className="flex items-center flex-row">
                   
                    <AiOutlineMail />
                    &nbsp; contact@bitbluetech.com
                  </div>
                </li>
                <li className="mb-4">
                  <div className="flex items-center ">
                    <FaAddressCard />
                    &nbsp;&nbsp; G1, Oswal Nagri,
                  </div>
                  Building No. 2A, Opp. Muthoot Finance, Next to Central Park,
                  Nalasopara East - 401209.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Our Services
              </h3>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/mobileappdevelopment" className="hover:underline">
                    Mobile App Development
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/WebAppDevelopment" className="hover:underline">
                    Web App Development
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/Softwaredevelopmentconsulting" className="hover:underline">
                    Software development consulting
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/productdesign" className="hover:underline">
                    Product Design
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/Machine" className="hover:underline">
                    Machine Learning & AI
                  </Link>
                </li>
                

              </ul>
            </div>
            <div>
              <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Our Products
              </h3>
              <ul className="text-gray-500 dark:text-gray-400">
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/Dextro" className="hover:underline">
                    Dextro
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/Pabulum" className="hover:underline">
                    Pabulum
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/Hospo" className="hover:underline">
                    Hospo
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/bithome" className="hover:underline">
                    Bithome
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/digitalmarketting" className="hover:underline">
                  Digital Marketing
                  </Link>
                </li>
                <li className="mb-4" onClick={scrollToTop}>
                  <Link to="/Bitpay" className="hover:underline">
                    Bitpay
                  </Link>
                </li>
              </ul>
            </div>
          
            <div className="col-span-2">
              <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Location
              </h3>
              <iframe
  title="map"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.6865411656904!2d72.8155429743429!3d19.425945040895083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a968f74946d1%3A0x3c519eb00d53168c!2sBitblue%20Technology!5e0!3m2!1sen!2sin!4v1690521275838!5m2!1sen!2sin"
  className="rounded"
  style={{ border: "0" ,width:'100%',
 maxWidth:'100vw',
  height:'300px'}}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>
             
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="text-center">
            <motion.div  whileHover={{ scale: 0.9 }}  className="flex items-center justify-center mb-5 text-2xl font-bold text-gray-900 dark:text-white">
              <motion.img
              whileHover={{ scale: 0.8 }}
                src={logobit}
                className="h-6 mr-3 sm:h-9"
                alt="bitblue Logo"
                loading="lazy"
              />{" "}
            <b  className=" text-blue-900">BITBLUE </b>&nbsp;&nbsp; <b className="text-xl text-red-600">TECHNOLOGY</b> 
            </motion.div>
            <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
              © 2022-2023 Bitbluetech™. All Rights Reserved.
            </span>
            <ul className="flex justify-center mt-5 space-x-5">
              <li>
                <Link
                  to="https://www.facebook.com/Bitbluetech/"
                  target="_blank"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                 <BiLogoFacebookCircle size={20}/>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/profile/view?id=AAIAABp1IgQBWW6B--9JineoON_6Esqf-n8mP4I&trk=nav_responsive_tab_profile"
                  target="_blank"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                  <BiLogoLinkedin size={20} />
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/Bitbluetech01"
                  target="_blank"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                  <BiLogoTwitter size={20}/>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCc5Jlj24LE5cUThIaVihREA"
                  target="_blank"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                 <BiLogoYoutube size={20}/>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
