import React, { useState } from "react";
import { Link } from "react-router-dom";
import {logobit} from "../assets/index";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import {bitblue }from "../assets/index"
import { motion } from 'framer-motion';
import { FaAngleDown } from "react-icons/fa";


const Header = () => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleDiv = () => {
    setShowMenu(!showMenu);
  };
   const closeMenu = () => {
    setShowMenu(false);
    };

  return (
    <>
      <div>
      <div style={{ zIndex: "10" ,overflow: 'auto',height:'10%'}} >
        <header className="w-full dark:bg-gray-600" style={{zIndex:"900"}}>
          <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-600">
          <div className="lg:hidden w-full  flex items-center justify-center gap-12 flex-row " style={{height:'15vw',zIndex:'900',backgroundColor:"#fff"}} >
            <img src={bitblue} loading="lazy" alt="bitblue mobile" style={{width:'80%',height:'auto',zIndex:'900'}} className=" sm:top-2" />
             <div  className="right-0 dark:text-gary-600" style={{width:'10%'}}><button className="dark:text-dark" onClick={toggleDiv}>{showMenu ?  " ":<FiMenu size={22} color="#808080"/>  }</button></div>
            </div>
            <div className=" hidden lg:flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
              <Link to="#" className="flex items-center flex-row">
                <motion.img  whileHover={{ scale: 0.8 }}  
                  src={logobit}
                  className="h-10 mr-3 sm:h-9"
                  alt="Bitblue Logo"
                  style={{ width: "30%", height: "auto" }}
                  loading="lazy"
                />
                <p className="flex items-center flex-row gap-2">
                  <b  className=""style={{ fontSize: " 2rem", color: "#1c3874" }}>
                    BITBLUE
                  </b>
                 
                  <b className="text-red-600" style={{ fontSize: "1.5rem" }}>
                    TECHNOLOGY
                  </b>
                </p>
              </Link>

              <div
                className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1"
                // id="mobile-menu-2"
              >
                <ul className="flex flex-col mt-4 font-medium lg:flex-row sm:flex-col   lg:space-x-8 lg:mt-0">
                  <li>
                    <Link
                      to=""
                      className="block py-2 pl-3 pr-4 text-gray-900 bg-purple-700 rounded lg:bg-transparent lg:p-0 dark:text-white"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="block py-2 pl-3 pr-4 border-b text-gray-900 dark:text-white border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0   hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown">
                    <p className="flex dropbtn items-center justify-center rounded-2xl  text-gray-900 dark:text-white flex-row gap-1"> Services <FaAngleDown color="#718096"/></p>
                      {/* <label className="dropbtn rounded-2xl p-1 dark:text-white">Services</label> */}
                      <div className="dropdown-content rounded-lg">
                        <Link to="/mobileappdevelopment">
                          Mobile App Development
                        </Link>
                        <Link to="/WebAppDevelopment">Web App Development</Link>
                        <Link to="/Softwaredevelopmentconsulting">
                          Software Development Consulting
                        </Link>

                        <Link to="/ProductDesign">Product Design</Link>

                        <Link to="/Machine">Machine Learning and AI</Link>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="dropdown">
                    <p className="flex dropbtn items-center justify-center rounded-2xl  text-gray-900 dark:text-white flex-row gap-1"> Product <FaAngleDown color="#718096"/></p>
                      {/* <label className="dropbtn  rounded-2xl p-1 dark:text-white"></label> */}
                      <div className="dropdown-content rounded-lg">
                        <Link to="/Dextro">Dextro</Link>
                        <Link to="/Pabulum">Pabulum</Link>
                        <Link to="/Hospo">Hospo</Link>

                        <Link to="/Bithome">Bithome</Link>

                        <Link to="/DigitalMarketting">Digital Marketing</Link>
                        <Link to="/Bitpay">Bitpay</Link>
                      </div>
                    </div>
                    {/* <Link to="/product" className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Product</Link> */}
                  </li>
                  <li>
                    <Link
                      to="/career"
                      className="block py-2 pl-3 pr-4 text-gray-900 dark:text-white  border-b border-gray-100  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contactus"
                      className="block py-2 pl-3 pr-4 text-gray-900 dark:text-white border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        </div>

        {showMenu && (
          <div
            className="w-full h-full lg:hidden  fixed top-20  bg-white  dark:bg-white  p-4 rounded-lg border "
            style={{ zIndex: "900",maxHeight:'400px' }}
          >

<div className="flex items-center justify-end"><div onClick={()=>{setShowMenu(!showMenu)}}><button className="dark:text-white"> <MdClose size={22} color="#808080" /></button></div></div>
            <div className="flex flex-col gap-8 py-2 fixed  top-20 ">
              <ul className="flex flex-col gap-4">
                <li className="text-base font-normal font-bold text-gray-900 tracking-wide cursor-pointer hover:text-designColor duration-300">
                  <Link to="" onClick={closeMenu}>Home</Link>
                </li>
                <li className="text-base font-normal text-gray-900 tracking-wide cursor-pointer hover:text-designColor duration-300">
                  <Link to="/about" onClick={closeMenu}>About</Link>
                </li>
                <li className="text-base font-normal text-gray-900 tracking-wide cursor-pointer hover:text-designColor duration-300">
                  <div className="dropdown ">
                    <p className="flex  dropbtn  items-center justify-center text-gray-900 flex-row gap-1"> 
                     Services<FaAngleDown color="#718096"/></p>
                  
                    <div className="dropdown-content rounded-lg" style={{ marginLeft: "65%" }}>
                      <Link to="/mobileappdevelopment" onClick={closeMenu}>
                        Mobile App Development
                      </Link>
                      <Link to="/WebAppDevelopment" onClick={closeMenu}>Web App Development</Link>
                      <Link to="/Softwaredevelopmentconsulting" onClick={closeMenu}>
                        Software Development Consulting
                      </Link>

                      <Link to="/ProductDesign" onClick={closeMenu}>Product Design</Link>

                      <Link to="/Machine" onClick={closeMenu}>Machine Learning and AI</Link>
                    </div>
                  </div>
                
                </li>
                <li className="text-base font-normal text-gray-900 tracking-wide cursor-pointer hover:text-designColor duration-300">
                  <div className="dropdown">
                    
                    <p className="flex dropbtn items-center justify-center text-gray-900 flex-row gap-1"> Product <FaAngleDown color="#718096"/></p>
                    
                    <div className="dropdown-content rounded-2xl sm:gap-1" style={{ marginLeft: "65%" }}>
                      <Link to="/Dextro" onClick={closeMenu}>Dextro</Link>
                      <Link to="/Pabulum" onClick={closeMenu}>Pabulum</Link>
                      <Link to="/Hospo" onClick={closeMenu}>Hospo</Link>

                      <Link to="/Bithome" onClick={closeMenu}>Bithome</Link>

                      <Link to="/DigitalMarketting" onClick={closeMenu}>Digital Marketing</Link>
                      <Link to="/Bitpay" onClick={closeMenu}>Bitpay</Link>
                    </div>
                  </div>
                </li>
                <li className="text-base font-normal text-gray-900 tracking-wide cursor-pointer hover:text-designColor duration-300">
                  <Link
                    to="/career" onClick={closeMenu}
                  >
                    Career
                  </Link>
                </li>
                <li className="text-base font-normal text-gray-900 tracking-wide cursor-pointer hover:text-designColor duration-300">
                  <Link
                     to="/contactus" onClick={closeMenu}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>

             
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
