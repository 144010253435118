import React from 'react';
import {productdesign,initiation,productperformace,ui} from "../../../assets/index"
import {MdOutlineWeb} from 'react-icons/md'




const ProductDesign = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900 ">
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Product Design</p>
      <div className='flex items-center justify-center rounded'>
        <img src={productdesign} alt='Software Development Consulting' className='rounded-2xl' style={{width:'60%',height:'auto'}} loading="lazy"/>
      </div>
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Product design is not limited to the look of your app. A seamless user experience is achieved through the synergy of in-depth business analysis, stunning visual identity, and a deep understanding of UI/UX design principles combined with a thorough development effort. Bitblue Technology provides full-cycle product design services to help your product resonate with its audience.

project initiation </p>
      <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center mt-8">Project Initiation Workshops</p>
{/* 
      <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">In-house/Online </p> */}
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
     
        <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">In-house/Online</p>
               
              
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Scoping session 
                       
                        
                      </div>
                      <p className=" mb-4 font-medium dark:text-white "> - You have a prototype or wireframes of the product</p>
                      <p className=" mb-4 font-medium dark:text-white"> - You get analysis of technical opportunities and information about a cost of development</p>
                      <p className=" mb-4 font-medium dark:text-white"> - Time 1 day in-house, 1-2 days online session (4-6h)</p>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Full product design workshops
                         
                      </div>
                      <p className=" mb-4 font-medium dark:text-white"> - you have an idea of the product with a few unknowns</p>
                      <p className=" mb-4 font-medium dark:text-white "> - you get a full stack team to help in transforming the idea into a mature solution</p>
                      <p className=" mb-4 font-medium dark:text-white"> - Time 2 days in-house, 2-3 days online session (8-12h)</p>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Design sprint
                         
                      </div>
                      <p className=" mb-4 font-medium dark:text-white"> - You have a complex solution that requires verification</p>
                      <p className=" mb-4 font-medium dark:text-white"> - You get a genuine validation based on research and prototype tested with potential users</p>
                      <p className=" mb-4 font-medium dark:text-white"> - Time 5 days in-house, 5-7 days online session (24-30h)</p>
                    </div>
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={initiation} alt='Inhouse/online' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
            <div className="col-span-2  text-center">
                
               
                <img src={productperformace} alt='Product Performance' style={{width:'100%',height:'auto'}} loading="lazy"/>
             </div>
             
            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">Product Performance and Usability Analysis</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                       <MdOutlineWeb/> UX research/test - consultation
                       
                        
                      </div>
                      <p className=" mb-4 font-medium dark:text-white "> - you have questions and unknowns regarding your users needs, market opportunities</p>
                      <p className=" mb-4 font-medium dark:text-white "> - you get validate ideas, test the solutions and get a feedback</p>
                    
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        UX audits
                        
                      </div>
                      <p className=" mb-4 font-medium dark:text-white"> - you have already existing website or application</p>
                      <p className=" mb-4 font-medium dark:text-white"> - you get optimize work, boost conversion, make users more satisfied</p>
                     
                    </div>    
                    
                </div>
            </div>

            <div className="col-span-2 mb-8">
                <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500">User Interface Design</p>
               
                <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">User Interface (UI) Design focuses on anticipating what users might need to do and ensuring that the interface has elements that are easy to access, understand, and use to facilitate those actions. UI brings together concepts from interaction design, visual design, and information architecture.</p>
                <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Web & Mobile design
                        
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Animations
                         
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Illustration
                         
                      </div>
                    </div>
                    <div>
                        <div className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
                        Visual Branding
                         
                      </div>
                    </div>
                </div>
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={ui} alt='User Interface Design' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
        
            
       
            
        </div>
{/*        
        <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
        <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p> */}

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default ProductDesign;
