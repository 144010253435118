import React from 'react';
import '../../assets/style/Style.css'

const ResponsiveCardService = ({ imageSrc, title, description,link,icon }) => (
  <div className='service' 
  style={{
    width: '400px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign:'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px',
    overflow: 'hidden',
    display: 'flex',
  justifyContent:' center',
  flexDirection:'column', /* Horizontal center */
  alignItems: 'center',
  }}>
    <div className="servicecontainer">
    <img src={imageSrc} alt={title} loading="lazy" style={{
      width: '200px',
      height: 'auto',
      paddingTop:'10px',
      borderRadius:'50%',
      objectFit: 'cover',
    }} />
    
    </div> 
    
    <div style={{ padding: '16px' }}>
      <h3  className="dark:text-gray-900" style={{ margin: 0, padding: 0, fontSize: '1.5rem',textAlign:'center' }}>{title}</h3>
      <p  className="dark:text-gray-700" style={{ }}>{description}</p>
    </div>
   
  </div>
);

export default ResponsiveCardService;