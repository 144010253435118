import React from 'react';
import {pabulum,restaurant,resthome }from "../../../assets/index"



const Pabulum = () => {
  return (
    <div className='p-10 items-center'>
      
      <section className="bg-white dark:bg-gray-900">
 <div className='flex items-center justify-center flex-row'><img src={pabulum} alt='dxlogo' style={{width: '80px',height:'auto'}} loading="lazy"/><span className="dark:text-white text-gray-700" style={{fontFamily: "'Pacifico', cursive",  fontSize: '3rem'}} >Pabulum</span> </div>
      {/* <p className="text-3xl mb-4 font-medium text-purple-600 dark:text-purple-500 text-center">Dextro </p> */}
      <div className='flex items-center justify-center rounded'>
        <img src={restaurant} alt='restaurant' className='rounded-2xl' style={{width:'40%',maxHeight:'450px',height:'auto'}} loading="lazy"/>
      </div>
      <p style={{fontSize:'18px'}} className="font-bold text-sm text-dark-500 sm:text-xl dark:text-white text-start">Pabulum is a comprehensive platform for the hospi tali ty industry developed ground up to address two key challenges that currently exist in the hospitality space.An enhanced consumer experience, optimal revenue management . The PABULUM framework consists of various components that come together to give patrons a rich and personalized experience when interacting with their hospitality provider and at the same time give tools to the provider to optimize revenues, enhance operations and reduce operational costs.</p>
      
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Why Pabulum ?</p>
      <p style={{fontSize:'20px'}} className=" mb-4  text-start mt-8 dark:text-white"><i>Menu management at restaurants is typically a very expensive process – not simply in terms of direct costs but also through intangible indirect costs like – varying commodity prices, ability to react to consumer feedback, stock outs and other similar issues. All of these translate into a need for restaurants having a platform where the consumer facing menu needs to be updated dynamically.<br/> The PABULUM Android/iPad digital menu platform seeks to enhance the diner experience, empower them to get a more personalized service while giving a powerful revenue management tool in the hands of the restaurant management. All this, without having to depend on infrastructure – internet connectivity. The PABULUM end-to-end platform has a hybrid architecture which ensures that the restaurant continues to function without any dependence on connectivity and allows restaurant management to dynamically make changes to menu items, prices, availability information, images, videos, or any other related updates.</i> </p>
       
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Key Features</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 1. Enhanced dining experience for your guests.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">Intuitive User Interface – an interface designed for the ease of all. Multilingual Support – Present your menu in multiple languages. Request Assistance – no need to catch the waiter’s eye. </p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 2. Enhanced Revenue Management.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">Dynamic Updates to Menus – managed automatically by the PABULUM Content Management Server ( CMS ). Dynamic Pricing Updates – ensure better bottom line management as commodity prices change. </p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 3. Workflow Efficiencies - Increase your Table Turns.</p>

      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white"><b>Empower your Guests</b> – allow guests to construct and place their orders, enter special requests and offer a personalized service.<br/> <b>Focus on Customer Service</b> – your captains/servers now focuses on customer service rather than in order taking workflows. Orders submitted by guests are authorized and sent to the Kitchen after review, edits and modifications by the server/captain. The PABULUM CMS applications running on the specially configured Windows pc allow them to manage the experience for their customers and time the order to the kitchen while still managing the entire floor.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 4. Your Menu - Your Brand - Your Layouts.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">The PABULUM Menu at each restaurant is customized to ensure that the character of the restaurant is reflected on the menu. Seasonal Menus – Restaurant management have the ability to keep updating their menu with the seasonal updates and change themes</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 5. Social Media - beyond simple web presence.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">Social Media today simply means creating presence for your property on facebook, twitter and other social media networks. However, the challenge is always to draw traffic to these web properties that you’ve created. Pabulum now allows the restaurant to capitalize a moment with the guest and allow them to like their favorite items, promote their likes on their personal facebook wall to all their social friends and “like” the facebook page of the restaurant.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 6. Advertising Revenues – A new stream of revenue.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">Use your menus to generate advertising revenues from associated partners. PABULUM enables a unique opportunity with context based menus that enable targeted advertising, joint ad- campaigns, cross selling opportunities - all without affecting the consumer experience</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 7. Customer Support</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">We believe in preventive support rather than being reactive to your problems. PABULUM has a strong field support operation that ensures timely service for your operation, when you need it. We have 24x 7 customer support like most providers that service this industry, however, we serve you not through a black box call center, but with a personalized relationship manager or a PABULUM network partner who is assigned and available when you need service.</p>
      <p className="text-3xl mb-4 font-bold  text-start mt-8 dark:text-white"> 8. POS Integration.</p>
      <p style={{fontSize:'18px'}} className=" mb-4 font-italic  text-start mt-8 dark:text-white">POS integration with most of the major POS vendors (listed below) ensure that existing operational and kitchen procedures remain the same and PABULUM only enhances the existing operation at the restaurant.</p>

      
      
      


     
        
      <p className="text-3xl mb-4 font-medium text-blue-600 dark:text-blue-500 text-center mt-8">Some additional features</p>
        
      <p  className=" text-2xl font-italic  text-start mt-8 dark:text-white">Some of the key benefits of Pabulum Digital Menus for restaurants are : </p>

        
        <div className="items-center max-w-screen-xl px-4  mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24  lg:px-6">
     
        <div className="col-span-2 mb-8">
             <div style={{fontSize:'18px'}} className="inline-flex items-center font-bold  ">
                        Pabulum Digital Menu
                  </div>

                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">End to End Digital Menu Platform for Restaurants .</p>
                <div style={{fontSize:'18px'}} className="inline-flex items-center font-bold  ">
                Pabulum Content Management Server
                  </div>   
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">Self-service kiosk application for Quick Service Restaurants ( QSR ).</p>
                <div style={{fontSize:'18px'}} className="inline-flex items-center font-bold  dark:text-white">
                Pabulum In Room
                  </div>  
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">Hotel and concierge app that enables cost reductions and services aggregation for hotels.</p>
                <div style={{fontSize:'18px'}} className="inline-flex items-center font-bold dark:text-white  ">
                Online Ordering
                  </div>
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">Consumer facing Online Ordering portal for Takeaway and delivery.</p>
                <div style={{fontSize:'18px'}} className="inline-flex items-center font-bold dark:text-white ">
                Pabulum Mobile
                  </div>
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">Consumer facing Online Ordering portal for Takeaway and delivery.</p>
                <div style={{fontSize:'18px'}} className="inline-flex items-center font-bold dark:text-white ">
                Media Net
                  </div>
                <p className="font-normal text-gray-500 sm:text-xl dark:text-gray-400">Fully integrated Digital Signage system for restaurants to promote today’s menu.</p>



              
            </div>
        <div className="col-span-2   text-center">
                
               
               <img src={resthome } alt='Restorant from home' style={{width:'100%',height:'auto'}} loading="lazy"/>
            </div>
          
         </div>
        {/* <p className="text-3xl mb-4 font-medium text-dark-600 dark:text-dark-500 text-center">Want to benefit from all these features ?</p>
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center">Our team, can guide you through our process, recent projects and understand how we can unlock the potential of your space today.</p>
     <p className=" text-center">
        <Link to="/contactus" >
        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
  Book a meeting
</button>
        </Link>
        </p>
         */}
        

        
    </section>

     
      {/* Add your content for Mobile App Development */}
    </div>
  );
};

export default Pabulum;
