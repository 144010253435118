import React from "react";
import ResponsiveCard from "./ResponsiveCardService";


import {taug,pdt,mlai,sfm,sdna,pd,sdc,webicon,mobileapp } from "../../assets/index"



import titleIcon from "../../assets/img/title-icon.png";


const Serviceindex = () => {
  return (
    <div style={{ marginTop: "8%" }}>
      <section className="bg-white dark:bg-gray-900">
        <div className="items-center flex flex-row justify-center gap-6">
          <img src={titleIcon} alt="titleIcon" loading="lazy"/>
      
          <h1 className="text-center font-bold text-2xl">OUR SERVICES</h1>
         
          <img src={titleIcon} alt="titleIcon" loading="lazy" />
        </div>
        <div className="items-center flex flex-row justify-center">
          <h1 className="text-center font-bold text-4xl">
            You Take Growth For Business
          </h1>
        </div>

        <div
          className="flex justify-center flex-col flex-wrap items-center p-2 "
          style={{ flexDirection: "row" }}
        >
          <ResponsiveCard
            
             imageSrc={mobileapp}
            title="Mobile App Development"
            description="Create, publish, and promote engaging content to generate more traffic and build a dedicated community."
          />
          <ResponsiveCard
           
             imageSrc={webicon}
            title="Web App Development"
            description="Your website has to impress your visitors within just a few seconds. If it runs slow, if it feels outdated.We are best developers"
          />

          <ResponsiveCard
           
          imageSrc={sdc}
            title="Software development consulting"
            description="You can provide the answers that your potential customers are trying to find, so you can become the industry."
          />
          <ResponsiveCard
           
         imageSrc={pd}
            title="Product Design"
            description="Get more website traffic, more customers, and more online visibility with powerful SEO services."
          />
          <ResponsiveCard
          
             imageSrc={sdna}
            title="Startup DNA"
            description="Create and manage top-performing social campaigns and start developing a dedicated customer fan base."
          />
          <ResponsiveCard
            
           imageSrc={sfm}
            title="Software Modernization"
            description="You can provide the answers that your potential customers are trying to find, so you can become the industry."
          />
          <ResponsiveCard
           
             imageSrc={mlai}
            title="Machine Learning and AI"
            description="You can provide the answers that your potential customers are trying to find, so you can become the industry."
          />
          <ResponsiveCard
           
             imageSrc={pdt}
            title="Dedicated Product Team"
            description="You can provide the answers that your potential customers are trying to find, so you can become the industry."
          />
          <ResponsiveCard
            
            imageSrc={taug}
            title="Team Augmentation"
            description="You can provide the answers that your potential customers are trying to find, so you can become the industry."
          />
        </div>
      </section>
    </div>
  );
};

export default Serviceindex;
